'use strict'

###*
 # @ngdoc object
 # @name mundoAdmin.assets
 # @description

###
angular
  .module 'mundoAdmin.assets', [
    'ui.router'
  ]
  .run [
    'MundoNavigation'
    (MundoNavigation) ->
      MundoNavigation.addSection
        state: 'assets'
        label: 'app.admin.assets'
        icon: 'business_center'
        showExpression: 'hasPermission("access asset section")'
        items: [
        #     state: 'assets.overview'
        #     label: 'app.admin.pageTitles.assets.assetsOverview'
        #     icon: 'list'
        #     showExpression: 'hasPermission("view all MundoMosaAssetBundle:Asset entities")'
        #   ,
            state: 'assets.assets'
            label: 'app.admin.pageTitles.assets.assets'
            icon: 'business_center'
            showExpression: 'hasPermission("view all MundoMosaAssetBundle:Asset entities")'
          ,
            state: 'assets.types'
            label: 'app.admin.pageTitles.assets.types'
            icon: 'next_week'
            showExpression: 'hasPermission("view all MundoMosaAssetBundle:Asset entities")'
        ]
  ]
